@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  & > button {
    width: 100%;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.showHidePass {
  position: absolute;
  top: 34px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.formFld {
  width: 100%;
  position: relative;
  &:not(:first-child) {
    margin-top: 24px;
    @media (--viewportMedium) {
      margin-top: 0;
    }
  }
  &:not(:last-child) {
    @media (--viewportMedium) {
      margin-right: 15px;
    }
  }
}
