@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
}
.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  & > button {
    width: 100%;
  }
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.showHidePass {
  position: absolute;
  top: 34px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.formFld {
  width: 100%;
  position: relative;
  &:not(:first-child) {
    margin-top: 24px;
    @media (--viewportMedium) {
      margin-top: 0;
    }
  }
  &:not(:last-child) {
    @media (--viewportMedium) {
      margin-right: 15px;
    }
  }
}
